import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './registerServiceWorker'

import './assets/styles/main.css'

import Meta from 'vue-meta'
Vue.use(Meta, { refreshOnceOnNavigation : true })

import AudioVisual from 'vue-audio-visual'
Vue.use(AudioVisual)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
