import azuracast from 'utils/api/azuracast'

const state = { 
    status: null,
    current: null,
    mount: null,
    data: [],
    nowplaying: null
}

export default {
    state,
    getters: {
        // currentArtSrc: (getters) => {
        //     if(!process.env.VUE_APP_ALBUM_API_OLD) return null;
            
        //     const currentSong = getters.currentSong;
        //     var artObj = {
        //         artist: '',
        //         title: '',
        //     };

        //     if(currentSong) {
        //         artObj['artist'] = currentSong.artist || '';
        //         artObj['title'] = currentSong.title || '';
        //     }
        //     const queryParams = new URLSearchParams(artObj).toString();
        //     return `${process.env.VUE_APP_ALBUM_API_OLD}?${queryParams}`;
        // },
        currentStation: (state) => {
            if(state.current != null && state.data.find(x => x.station.id == state.current)) {
                return state.data.find(x => x.station.id == state.current).station
            }
            return null;
        },
        currentSong: (state) => {
            if(state.current != null && state.data.find(x => x.station.id == state.current)) {
                let x = state.data.find(x => x.station.id == state.current);
                if(x && x.now_playing.song){ return x.now_playing.song }
            }
            return null;
        },
        getStationMounts: (state, getters) => {
            const station = getters.currentStation
            if(station != null){
                return station.mounts;
            }
            return [];
        },
        currentMount: (state, getters) => {
            const station = getters.currentStation
            if(station != null){
                if(state.mount != null && station.mounts.find(x => x.id == state.mount)) {
                    return station.mounts.find(x => x.id == state.mount)
                }
            }
            return null;
        },
        isCurrentMount: (state, getters) => mount_id => {
            const mount = getters.currentMount
            return mount && mount.id == mount_id;
        },
        currentSource: (state, getters) => {
            const mount = getters.currentMount
            if(mount) {
                return mount.url;
            }
            return null;
        }
    },
    mutations: {
        loading: (state) => {
            state.status = 'loading'
        },
        'set-all-stations': (state, data) => {
            if(state.data == data) { return; }
            state.data = data

            if(state.data.length && (state.current == null || !state.data.find(x => x.station.id == state.current))){
                let [first_station] = state.data
                state.current = first_station.station.id
                let [first_mount] = first_station.station.mounts
                if(state.mount != first_mount.id) {
                    state.mount = first_mount.id
                }
                // commit('set-current-station', first_station.station.id)
            }

            state.status = null
        },
        error: (state) => {
            state.status = 'error'
        },
        'set-current-station': (state, station_id) => {
            state.current = null
            state.mount = null
            const found = state.data.find(x => x.station.id == station_id)
            if(found) {
                state.current = found.station.id
                let [first_mount] = found.station.mounts
                if(state.mount != first_mount.id) {
                    state.mount = first_mount.id
                }
            }
        },
        'set-current-mount': (state, id) => {
            state.mount = id
            /* state.current = null
            if(state.data.find(x => x.station.id == id)) {
                state.current = id
            } */
        },
    },
    actions: {
        setCurrentStation: ({commit}, station_id) => {
            commit('set-current-station', station_id)
        },
        setCurrentMount: ({commit}, mount_id) => {
            commit('set-current-mount', mount_id)
        },
        'load-nowplaying': ({commit/* , dispatch */}) => {
            // const vm = this;
            return new Promise((resolve, reject) => {
                console.log('loading stations')
                commit('loading')
                azuracast.get('/nowplaying')
                    .then(resp => {
                        commit('set-all-stations', resp.data)
                        /* if(!vm.state.current){
                            dispatch('set-station')
                        } */
                        // dispatch()
                        resolve(resp)
                    })
                    .catch(error => {
                        commit('error', error)
                        reject(error)
                    })
            })
        }
    }
}