<template>
  <div class="flex flex-col w-screen h-screen overflow-hidden bg-primary-800">

    <sidebar :menu-state="!!menuState" v-on:closed-sidebar="setMenuState(false)" :aria-expanded="!!menuState" />

    <div class="flex flex-col flex-1 w-full h-full min-h-screen overflow-hidden">

      <div class="top-0 left-0 pt-4 pl-4" v-show="!menuState"
        :class="{ 'z-50 fixed': !menuState, 'hidden': !!menuState }">
        <button type="button"
          class="bg-white -ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-800 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          v-on:click="setMenuState(true)">
          <span class="sr-only">Open sidebar</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
            class="w-8 h-8 text-primary-800">
            <path fill-rule="evenodd"
              d="M17.45 3.473a.75.75 0 10-.4-1.446L5.313 5.265c-.84.096-1.671.217-2.495.362A2.212 2.212 0 001 7.817v7.933A2.25 2.25 0 003.25 18h13.5A2.25 2.25 0 0019 15.75V7.816c0-1.06-.745-2-1.817-2.189a41.124 41.124 0 00-5.406-.589l5.673-1.565zM16 9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM14.5 16a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-9.26-5a.75.75 0 01.75-.75H6a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V11zm2.75-.75a.75.75 0 00-.75.75v.01c0 .415.336.75.75.75H8a.75.75 0 00.75-.75V11a.75.75 0 00-.75-.75h-.01zm-1.75-1.5A.75.75 0 016.99 8H7a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75v-.01zm3.583.42a.75.75 0 00-1.06 0l-.007.007a.75.75 0 000 1.06l.007.008a.75.75 0 001.06 0l.007-.007a.75.75 0 000-1.061l-.007-.007zm.427 2.08A.75.75 0 0111 12v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V12a.75.75 0 01.75-.75h.01zm-.42 3.584a.75.75 0 000-1.061l-.007-.007a.75.75 0 00-1.06 0l-.007.007a.75.75 0 000 1.06l.007.008a.75.75 0 001.06 0l.008-.007zm-3.59.416a.75.75 0 01.75-.75H7a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75v-.01zm-1.013-1.484a.75.75 0 00-1.06 0l-.008.007a.75.75 0 000 1.06l.007.008a.75.75 0 001.061 0l.007-.007a.75.75 0 000-1.061l-.007-.007zM3.75 11.25a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V12a.75.75 0 01.75-.75h.01zm1.484-1.012a.75.75 0 000-1.061l-.007-.007a.75.75 0 00-1.06 0l-.007.007a.75.75 0 000 1.06l.007.008a.75.75 0 001.06 0l.007-.007zM7.24 13a.75.75 0 01.75-.75H8a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V13zm-1.25-.75a.75.75 0 00-.75.75v.01c0 .415.336.75.75.75H6a.75.75 0 00.75-.75V13a.75.75 0 00-.75-.75h-.01z"
              clip-rule="evenodd" />
          </svg>
        </button>
      </div>

      <main
        class="z-0 flex items-center justify-center flex-1 h-full min-h-screen overflow-y-auto focus:outline-none bg-gradient-to-bl from-primary-900 via-primary-900 to-primary-800 sm:pb-48"
        tabindex="0">
        <!-- <background-layer class="absolute inset-0 z-0 w-full h-full opacity-20" /> -->
        <!-- <AVCircle /> -->

        <div
          class="relative z-10 items-center justify-start hidden max-w-lg my-auto overflow-hidden shadow-xl rounded-3xl w-96 h-96 aspect-square md:flex ring-primary-500 ring-opacity-25 ring-8 lg:w-auto lg:h-auto">
          <img :src="currentArtSrc" v-if="currentArtSrc"
            class="object-cover object-center w-full h-full pointer-events-none select-none" />
        </div>

        <img :src="currentArtSrc" v-if="currentArtSrc"
          class="absolute inset-0 z-0 object-cover object-center w-full h-full opacity-50 pointer-events-none select-none filter blur-xl" />

        <!-- <pre v-if="currentStation"
                                                                                                                                            class="absolute p-2 prose bg-white rounded shadow-md bottom-2 left-2 right-2 max-w-none">
                                                                                                                                                {{ JSON.stringify(currentStation, null, 2) }}
                                                                                                                                              </pre> -->
      </main>
      <player />

    </div>
  </div>
</template>

<script>
import Player from './components/Player'
import Sidebar from './components/Sidebar'
// import BackgroundLayer from "components/Background"
import { mapGetters } from "vuex"

export default {
  name: 'App',
  components: {
    Sidebar,
    Player,
    // BackgroundLayer,
    // AVCircle
  },
  data: () => ({
    state: 'loading',
    menuState: true,
  }),
  methods: {
    setMenuState(state) {
      console.log('setMenuState', state)
      this.menuState = state
    }
  },
  computed: {
    ...mapGetters(["currentSong", "currentStation"]),
    currentArtSrc() {
      // warning, repeated code because proces.env wasn't taking in store for some reason
      if (!this.currentStation || !this.currentSong || !process.env.VUE_APP_ALBUM_API_OLD) return null;
      const artObj = {
        artist_first: 'y',
        station: 'musicstars' /*+ (this.currentStation.shortcode ? '-' + this.currentStation.shortcode : '')*/,
        artist: this.currentSong.artist || '',
        title: this.currentSong.title || '',
      };
      const queryParams = new URLSearchParams(artObj).toString();
      return `${process.env.VUE_APP_ALBUM_API_OLD}?${queryParams}`;
    }
  }
}
</script>
