
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_AZURA_API,
    headers: {
        'Accept': "application/json",
        'Content-Type': "application/json"
    }
});

/* api.interceptors.request.use(
    config => {
        const token = localStorage.getItem([AUTH_TOKEN_IDENTIFIER])
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
); */

export default api