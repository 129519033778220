// import 'es6-promise/auto'
// import { createStore } from "vuex" // vue3
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// import player from './modules/player'
import station from './modules/station'

const debug = process.env.NODE_ENV !== "production";

// const store = createStore({
const store = new Vuex.Store({
    modules: {
        // player,
        station
    },
    strict: debug
})

export default store