<template>
  <div class="fixed z-50 flex w-auto h-full max-w-full min-h-screen">
    <transition enter-active-class="transition-opacity duration-300 ease-linear" enter-from-class="opacity-0"
      enter-to-class="opacity-100" leave-active-class="transition-opacity duration-300 ease-linear"
      leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div class="fixed inset-0" v-show="menuState" v-on:click="close">
        <div class="absolute inset-0 opacity-75 bg-primary-600"></div>
      </div>
    </transition>

    <transition enter-active-class="transition duration-300 ease-in-out transform" enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0" leave-active-class="transition duration-300 ease-in-out transform"
      leave-from-class="translate-x-0" leave-to-class="-translate-x-full">
      <div class="relative flex flex-col flex-1 w-full max-w-full max-w-screen bg-primary-800" v-show="menuState">
        <div class="absolute top-0 right-0 hidden -mr-12 md:block">
          <button
            class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            v-on:click="close">
            <span class="sr-only">Close sidebar</span>
            <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex flex-col flex-1 w-full h-full min-w-full p-0 overflow-y-auto md:max-w-md md:min-w-min">
          <div
            class="sticky inset-y-0 top-0 flex items-center flex-shrink-0 gap-4 px-4 py-2 shadow-xl bg-primary-900 backdrop-filter backdrop-blur backdrop-opacity-75 bg-opacity-70">
            <!-- <logo class="w-auto h-8 text-white fill-white" /> -->
            <h1 class="flex-grow text-2xl font-bold text-white">Music Stars</h1>
            <button
              class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-600"
              v-on:click="descrState = !descrState">
              <span class="sr-only">Show descriptions</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white">
                <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                  clip-rule="evenodd" />
              </svg>
            </button>
            <button
              class="flex items-center justify-center w-10 h-10 ml-1 rounded-full md:hidden focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-600"
              v-on:click="close">
              <span class="sr-only">Close sidebar</span>
              <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <nav class="flex-1 px-2 pb-4 mt-5 space-y-1">
            <template v-if="stations">
              <div class="max-w-full space-y-1 text-left">
                <button type="button" v-on:click="setCurrentStation(x.station.id)" v-for="x in stations"
                  v-bind:key="x.station.id"
                  class="flex items-center w-full px-2 py-2 text-base font-medium text-left border-b-2 rounded-md border-primary-900 group focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-600"
                  :class="{ 'text-white bg-primary-700 border-primary-400': currentStation.id == x.station.id, 'text-white bg-primary-900 hover:text-white hover:bg-primary-700': currentStation.id != x.station.id }">
                  <div class="flex-grow max-w-full overflow-hidden">
                    <h5 class="font-bold text-white truncate">{{ x.station.name || 'Unknown Station' }}</h5>

                    <div class="flex flex-row justify-start mt-1 space-x-2 align-middle" v-if="x.now_playing">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                        <path fill-rule="evenodd"
                          d="M17.721 1.599a.75.75 0 01.279.584v11.29a2.25 2.25 0 01-1.774 2.198l-2.041.442a2.216 2.216 0 01-.938-4.333l2.662-.576a.75.75 0 00.591-.734V6.112l-8 1.73v7.684a2.25 2.25 0 01-1.774 2.2l-2.042.44a2.216 2.216 0 11-.935-4.33l2.659-.574A.75.75 0 007 12.53V4.237a.75.75 0 01.591-.733l9.5-2.054a.75.75 0 01.63.149z"
                          clip-rule="evenodd" />
                      </svg>
                      <span class="font-mono text-xs font-semibold text-white truncate">{{ x.now_playing.song.text || '-'
                      }}</span>
                    </div>
                    <div v-if="descrState && x.station.description"
                      class="mt-4 font-mono text-sm font-semibold text-white">{{
                        x.station.description }}</div>
                  </div>
                </button>
              </div>
            </template>
          </nav>
        </div>
      </div>
    </transition>
    <div class="flex-shrink-0 hidden w-14 md:flex">
      <!-- Force sidebar to shrink to fit close icon -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
// import Logo from 'components/Logo';
// import Player from 'components/Player'

export default {
  name: 'Sidebar',
  components: {
    // Logo
  },
  props: {
    menuState: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    descrState: false
  }),
  /* props: {} */
  computed: {
    ...mapGetters(["currentStation"]),
    ...mapState({
      stations: state => state.station.data,
    })
  },
  methods: {
    ...mapActions(['setCurrentStation']),
    /* toggleSidebar() {
      this.open = !this.open
    } */
    close() {
      this.$emit('closed-sidebar')
    }
  }
}
</script>