<template>
    <div class="fixed inset-y-0 bottom-0 top-auto z-40 flex flex-col justify-between w-full h-auto bg-white shadow-lg">
        <!-- <div class="relative flex items-center justify-center flex-1 overflow-hidden pb-80 md:pb-60">
                                                                                                                                                                                                                                                                                                                                                                                                                <img class="object-cover w-full h-full" src="https://tailwindcss.com/img/card-top.jpg" alt="Album Pic">
                                                                                                                                                                                                                                                                                                                                                                                                            </div> -->
        <div class="flex flex-col w-full bg-gradient-to-br from-primary-900 to-primary-800">
            <audio ref="audio" :src="audioSource" class="absolute inset-x-0 hidden w-full h-6 bottom-full"
                :autoplay="playing" :volume="audioVolume" />
            <div
                class="flex flex-col justify-between flex-shrink-0 w-full h-full p-8 xl:flex-row md:flex-row md:items-center">
                <div class="relative flex items-center justify-center flex-shrink-0" v-if="currentArtSrc">
                    <div
                        class="relative flex flex-shrink-0 w-full h-full max-w-full m-0 mx-auto my-auto -mt-48 overflow-hidden rounded-full select-none xs:-mt-48 xs:w-64 xs:h-64 md:w-24 md:h-24 ring-2 ring-white aspect-square md:mt-0">
                        <img class="object-cover w-full h-full pointer-events-none" :src="currentArtSrc"
                            :alt="currentSong ? currentSong.text : ''" />
                    </div>

                </div>
                <div class="flex-1 pt-5 text-center truncate select-none md:pt-0 md:px-5 md:text-left">
                    <h2 class="mt-1 text-sm font-semibold truncate text-primary-400">{{ currentStation ? currentStation.name
                        : 'MusicStars' }}</h2>
                    <h3 class="text-2xl font-medium truncate text-primary-300">{{ currentSong ? currentSong.title : '' }}
                    </h3>
                    <p class="mt-1 text-sm truncate text-primary-400">{{ currentSong ? currentSong.artist : '' }}</p>
                    <!-- <span>{{ audioVolume }}</span> -->
                    <!-- <p>{{ audioSource }}</p> -->
                    <!-- <p>{{ optionsOpen ? 'open' : 'closed' }}</p> -->
                </div>
                <div class="flex items-center justify-around mt-8 md:mt-0 md:space-x-4 xl:space-x-6">
                    <div class="">
                        <button type="button"
                            class="p-4 text-gray-500 rounded-full shadow-lg select-none bg-gray-50 focus:outline-none hover:bg-white focus:bg-white focus:ring-4 ring-primary-500 ring-opacity-75 hover:animate-spin focus:animate-spin"
                            :class="{ 'bg-primary-100 text-primary-700': optionsOpen || !currentMount }"
                            v-on:click="optionsOpen = !optionsOpen" v-on-clickaway="clickAwayOptions">
                            <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <g fill="currentColor" v-if="!optionsOpen">
                                    <path
                                        d="M13.024 9.25c.47 0 .827-.433.637-.863a4 4 0 00-4.094-2.364c-.468.05-.665.576-.43.984l1.08 1.868a.75.75 0 00.649.375h2.158zM7.84 7.758c-.236-.408-.79-.5-1.068-.12A3.982 3.982 0 006 10c0 .884.287 1.7.772 2.363.278.38.832.287 1.068-.12l1.078-1.868a.75.75 0 000-.75L7.839 7.758zM9.138 12.993c-.235.408-.039.934.43.984a4 4 0 004.094-2.364c.19-.43-.168-.863-.638-.863h-2.158a.75.75 0 00-.65.375l-1.078 1.868z" />
                                    <path fill-rule="evenodd"
                                        d="M14.13 4.347l.644-1.117a.75.75 0 00-1.299-.75l-.644 1.116a6.954 6.954 0 00-2.081-.556V1.75a.75.75 0 00-1.5 0v1.29a6.954 6.954 0 00-2.081.556L6.525 2.48a.75.75 0 10-1.3.75l.645 1.117A7.04 7.04 0 004.347 5.87L3.23 5.225a.75.75 0 10-.75 1.3l1.116.644A6.954 6.954 0 003.04 9.25H1.75a.75.75 0 000 1.5h1.29c.078.733.27 1.433.556 2.081l-1.116.645a.75.75 0 10.75 1.298l1.117-.644a7.04 7.04 0 001.523 1.523l-.645 1.117a.75.75 0 101.3.75l.644-1.116a6.954 6.954 0 002.081.556v1.29a.75.75 0 001.5 0v-1.29a6.954 6.954 0 002.081-.556l.645 1.116a.75.75 0 001.299-.75l-.645-1.117a7.042 7.042 0 001.523-1.523l1.117.644a.75.75 0 00.75-1.298l-1.116-.645a6.954 6.954 0 00.556-2.081h1.29a.75.75 0 000-1.5h-1.29a6.954 6.954 0 00-.556-2.081l1.116-.644a.75.75 0 00-.75-1.3l-1.117.645a7.04 7.04 0 00-1.524-1.523zM10 4.5a5.475 5.475 0 00-2.781.754A5.527 5.527 0 005.22 7.277 5.475 5.475 0 004.5 10a5.475 5.475 0 00.752 2.777 5.527 5.527 0 002.028 2.004c.802.458 1.73.719 2.72.719a5.474 5.474 0 002.78-.753 5.527 5.527 0 002.001-2.027c.458-.802.719-1.73.719-2.72a5.475 5.475 0 00-.753-2.78 5.528 5.528 0 00-2.028-2.002A5.475 5.475 0 0010 4.5z"
                                        clip-rule="evenodd" />
                                </g>
                                <g fill="currentColor" v-else>
                                    <path fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd" />
                                </g>
                            </svg>
                        </button>
                        <transition enter-active-class="transition duration-100 ease-out"
                            enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-out"
                            leave-from-class="transform scale-100 opacity-100"
                            leave-to-class="transform scale-95 opacity-0">
                            <div class="absolute right-0 flex flex-col w-auto max-w-full min-w-full p-4 bg-white border-2 rounded shadow bottom-full border-primary-200 focus"
                                v-show="optionsOpen">
                                <button type="button" v-for="mount in getStationMounts" v-bind:key="mount.id"
                                    v-on:click="setCurrentMount(mount.id)"
                                    class="px-4 py-2.5 font-mono text-sm cursor-pointer"
                                    :class="{ 'bg-primary-200 font-mono': isCurrentMount(mount.id) }">{{ mount.name
                                    }}</button>
                                <input type="range" v-model="volume" min="0" max="100" step="1" class="h-12" />
                            </div>
                        </transition>
                    </div>
                    <!-- <Menu v-slot="{ open }">
                                                                                                                                                                                                                                                                                                                                                                                                                            <MenuButton :class="{'bg-primary-100 text-primary-700': open || !currentMount}" class="p-4 text-gray-500 rounded-full shadow-lg select-none bg-gray-50 focus:outline-none hover:bg-white focus:bg-white focus:ring-4 ring-primary-500 ring-opacity-75">
                                                                                                                                                                                                                                                                                                                                                                                                                                <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                                                                                                                                                                                                                                                                                                                                                                                    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" v-if="!open" />
                                                                                                                                                                                                                                                                                                                                                                                                                                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" v-else />
                                                                                                                                                                                                                                                                                                                                                                                                                                </svg>
                                                                                                                                                                                                                                                                                                                                                                                                                            </MenuButton>
                                                                                                                                                                                                                                                                                                                                                                                                                            <transition
                                                                                                                                                                                                                                                                                                                                                                                                                                enter-active-class="transition duration-100 ease-out"
                                                                                                                                                                                                                                                                                                                                                                                                                                enter-from-class="transform scale-95 opacity-0"
                                                                                                                                                                                                                                                                                                                                                                                                                                enter-to-class="transform scale-100 opacity-100"
                                                                                                                                                                                                                                                                                                                                                                                                                                leave-active-class="transition duration-75 ease-out"
                                                                                                                                                                                                                                                                                                                                                                                                                                leave-from-class="transform scale-100 opacity-100"
                                                                                                                                                                                                                                                                                                                                                                                                                                leave-to-class="transform scale-95 opacity-0"
                                                                                                                                                                                                                                                                                                                                                                                                                            >
                                                                                                                                                                                                                                                                                                                                                                                                                                <MenuItems
                                                                                                                                                                                                                                                                                                                                                                                                                                    class="absolute right-0 flex flex-col w-auto max-w-full min-w-full bg-white border-2 rounded shadow bottom-full border-primary-200 focus"
                                                                                                                                                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                                                                                                                                                    <div class="px-0.5 py-1 bg-primary-200 text-primary-900" disabled><span class="font-mono text-sm">Mounts</span></div>
                                                                                                                                                                                                                                                                                                                                                                                                                                    <MenuItem
                                                                                                                                                                                                                                                                                                                                                                                                                                        v-slot="{ active }" v-for="mount in getStationMounts"
                                                                                                                                                                                                                                                                                                                                                                                                                                        v-bind:key="mount.id"
                                                                                                                                                                                                                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                                                                                                                                                                                                                        <button type="button"
                                                                                                                                                                                                                                                                                                                                                                                                                                            v-on:click="setCurrentMount(mount.id)"
                                                                                                                                                                                                                                                                                                                                                                                                                                            :class="{ 'bg-primary-500': active, 'bg-primary-200 font-mono': isCurrentMount(mount.id) }" class="px-4 py-2.5 font-mono text-sm cursor-pointer"
                                                                                                                                                                                                                                                                                                                                                                                                                                        >{{ mount.name }}</button>
                                                                                                                                                                                                                                                                                                                                                                                                                                    </MenuItem>
                                                                                                                                                                                                                                                                                                                                                                                                                                    <input type="range" v-model="volume" min="0" max="100" step="1" class="h-12" />
                                                                                                                                                                                                                                                                                                                                                                                                                                </MenuItems>
                                                                                                                                                                                                                                                                                                                                                                                                                            </transition>
                                                                                                                                                                                                                                                                                                                                                                                                                        </Menu> -->
                    <!-- <div class="p-4 text-gray-500 bg-gray-100 rounded-full shadow-lg">
                                                                                                                                                                                                                                                                                                                                                                                                                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4 5h3v10H4V5zm12 0v10l-9-5 9-5z"/></svg>
                                                                                                                                                                                                                                                                                                                                                                                                                        </div> -->
                    <button type="button" v-on:click="togglePlaying"
                        class="p-8 rounded-full shadow-lg select-none text-primary-600 md:p-6 bg-gray-50 focus:outline-none hover:bg-white focus:bg-white focus:ring-4 ring-primary-500 ring-opacity-75"
                        :class="{ 'bg-gray-100': !loaded, 'text-primary-100 bg-primary-800': loaded && playing, 'ring-2': loaded && !playing }">
                        <svg :class="{ 'animate-spin-slow': !loaded, 'animate-pulse': loaded && playing }"
                            class="w-6 xs:w-8 xs:h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 10 10">
                            <g v-if="!loaded">
                                <path
                                    d="M9.485,5.783a.5.5,0,0,0-.606-.364l-2,.5a.5.5,0,0,0-.136.914l.559.335a3.152,3.152,0,0,1-4.776-.187.75.75,0,1,0-1.169.94,4.651,4.651,0,0,0,7.25.03l.636.382A.493.493,0,0,0,9.5,8.4a.5.5,0,0,0,.485-.621Z"
                                    fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="0"></path>
                                <path
                                    d="M3.5,3.978a.5.5,0,0,0-.238-.5l-.734-.44a3.156,3.156,0,0,1,5.2.338A.75.75,0,1,0,9.005,2.6a4.651,4.651,0,0,0-7.772-.339L.757,1.975a.5.5,0,0,0-.742.55l.5,2a.5.5,0,0,0,.606.364l2-.5A.5.5,0,0,0,3.5,3.978Z"
                                    fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="0"></path>
                            </g>
                            <g v-else-if="!playing">
                                <path
                                    d="M8.266,3.563,3.7.316h0A1.686,1.686,0,0,0,1.916.2,1.754,1.754,0,0,0,1,1.753V8.247A1.753,1.753,0,0,0,1.916,9.8,1.687,1.687,0,0,0,3.7,9.685L8.266,6.438a1.775,1.775,0,0,0,0-2.875Z"
                                    fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="0"></path>
                            </g>
                            <g v-else>
                                <path d="M0.000 0.000 L4.000 0.000 L4.000 10.000 L-0.000 10.000 Z" fill="currentColor"
                                    stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
                                <path d="M6.000 0.000 L10.000 0.000 L10.000 10.000 L6.000 10.000 Z" fill="currentColor"
                                    stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
                            </g>
                        </svg>
                    </button>
                    <!-- <div class="p-4 text-gray-500 bg-gray-100 rounded-full shadow-lg">
                                                                                                                                                                                                                                                                                                                                                                                                                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 5h3v10h-3V5zM4 5l9 5-9 5V5z"/></svg>
                                                                                                                                                                                                                                                                                                                                                                                                                        </div> -->

                    <button type="button" v-on:click="mute" :class="{ 'animate-pulse': !!muted }"
                        class="relative p-4 text-gray-500 rounded-full shadow-lg select-none bg-gray-50 focus:outline-none hover:bg-white focus:bg-white focus:ring-4 ring-primary-500 ring-opacity-75">
                        <span class="absolute top-0 left-0 right-0 -mt-5 text-xs font-black"
                            :class="{ 'text-primary-800': volume >= 80, 'text-primary-700': volume >= 50, 'text-primary-600': volume >= 10, 'text-red-500': volume < 10 }">{{
                                Math.round(audioVolume * 100) + `%` }}</span>
                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path fill-rule="evenodd"
                                d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z"
                                clip-rule="evenodd" v-if="muted" />
                            <path fill-rule="evenodd"
                                d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
                                clip-rule="evenodd" v-else />
                        </svg>
                    </button>
                </div>
            </div>

            <!-- <div class="flex flex-col flex-grow-0 h-16 py-4 mx-8 space-y-1">
                                                                                                                                                                                                                                                                                                                                                                                                                    <div class="flex justify-between text-sm font-semibold select-none text-primary-700">
                                                                                                                                                                                                                                                                                                                                                                                                                        <p>0:40</p>
                                                                                                                                                                                                                                                                                                                                                                                                                        <p>4:20</p>
                                                                                                                                                                                                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                                                                                                                                                                                                    <div class="px-2 mt-1">
                                                                                                                                                                                                                                                                                                                                                                                                                        <div class="h-1 rounded-full shadow bg-primary-400">
                                                                                                                                                                                                                                                                                                                                                                                                                            <div class="relative w-0 h-2 rounded-full">
                                                                                                                                                                                                                                                                                                                                                                                                                                <span class="w-4 h-4 bg-primary-500 absolute right-0 bottom-0 -mb-0.5 rounded-full shadow"></span>
                                                                                                                                                                                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                                                                                                                                                                                                </div> -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { mixin as clickaway } from 'vue-clickaway';
// import { avCircle } from 'vue-audio-visual'

// import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

export default {
    mixins: [clickaway],
    components: {
        /* Menu,
        MenuButton,
        MenuItems,
        MenuItem */
        // 'av-circle': avCircle
    },
    data: () => ({
        interval: undefined,
        source: null,
        audioSource: null,
        audio: undefined,
        loaded: false,
        playing: false,
        previousVolume: 35,
        volume: 50,
        playPromise: undefined,
        optionsOpen: false
    }),
    computed: {
        ...mapGetters(["currentSong", "currentStation", "currentMount", "currentSource", "getStationMounts", "isCurrentMount"]),
        currentArtSrc() {
            // warning, repeated code because proces.env wasn't taking in store for some reason
            if (!this.currentStation || !this.currentSong || !process.env.VUE_APP_ALBUM_API_OLD) return null;
            const artObj = {
                artist_first: 'y',
                station: this.currentStation.shortcode || 'musicstars',
                artist: this.currentSong.artist || '',
                title: this.currentSong.title || '',
            };
            const queryParams = new URLSearchParams(artObj).toString();
            return `${process.env.VUE_APP_ALBUM_API_OLD}?${queryParams}`;
        },
        /* isPlaying() {
            console.log('isPlaying ran')
            if(this.$refs.audio != null) {
                return !this.$refs.audio.paused
            }
            return false;
        } */
        /* autoPlay() {
            return this.currentSource && !this.muted && this.playing;
        }, */
        muted() {
            return Math.round(this.volume) / 100 === 0;
        }/* ,
		percentComplete() {
			return parseInt(this.currentSeconds / this.durationSeconds * 100);
        } */,
        audioVolume() {
            return this.volume / 100;
        }
    },
    watch: {
        source(newVal, oldVal) {
            var newUrl, oldUrl;
            try {
                newUrl = new URL(newVal);
                oldUrl = new URL(oldVal);
            } catch (error) {
                //
            }
            if (
                newUrl != null && oldUrl != null
            ) {
                if (newUrl.pathname != oldUrl.pathname) {
                    this.audioSource = newUrl;
                }
            } else if (newUrl != null) {
                this.audioSource = newUrl;
            }


            //this.audioSource = newVal;
        },
        playing(value) {
            console.log('playing watched', value, this.playPromise, this.audio.readyState)
            /* if(this.playPromise == undefined || this.audio.readyState < 2){
                this.playing = false;
                return;
            } */
            if (value && this.audio.paused) {

                this.source = this.currentSource

                return this.audio.play().then(() => { this.loaded = true }).catch(() => { this.loaded = false; this.playinng = false; })
            }
            this.audio.pause();
        },
        volume(/* value */) {
            this.audio.volume = this.volume / 100;
        },
        currentSource(value) {
            console.log('currentSource watched', value, this.source)
            if (this.source == value) { return; }
            this.source = value;
        },
        /* source(value) {
            if(this.playing){}
        }, */
        /* 'audio.readyState'(value) {
            console.log('readyState watched',value, this.playPromise, this.audio.readyState)
            this.loaded = false;
            if (this.audio.readyState >= 2) {
                this.loaded = true;
            }
        } */
    },
    methods: {
        ...mapActions(['setCurrentStation', 'setCurrentMount']),
        mute() {
            if (this.muted) {
                return this.volume = this.previousVolume;
            }

            this.previousVolume = this.volume;
            this.volume = 0;
        },
        load(e) {
            console.log('load', e);
            this.loaded = false;
            if (this.audio.readyState >= 2) {
                this.loaded = true;
            }
        },
        stop() {
            this.playing = false;
            this.audio.currentTime = 0;
        },
        togglePlaying() {
            if (this.playing) { return this.stop() }
            this.play()
        },
        play() {
            this.playing = true;
            // this.audio.currentTime = 0;
        },
        update(/* e */) {
            if (!this.loaded) return;
            this.currentSeconds = parseInt(this.audio.currentTime);
        },
        clickAwayOptions() {
            this.optionsOpen = false
        }
    },
    created() {
        this.innerLoop = this.loop;
    },
    mounted() {
        this.audio = this.$refs.audio;
        /* this.audio.crossOrigin = "anonymous" */
        this.audio.addEventListener('timeupdate', this.update);
        this.audio.addEventListener('loadeddata', this.load);
        this.audio.addEventListener('pause', () => { this.playing = false; });
        this.audio.addEventListener('play', () => { this.playing = true; });
        this.audio.addEventListener('ended', () => { this.playing = false; });
        this.audio.addEventListener('error', () => { this.playing = false; });

        this.$store.dispatch('load-nowplaying')

        this.interval = setInterval(() => { this.$store.dispatch('load-nowplaying') }, 30000)
    },
    metaInfo() {
        return {
            title: (this.muted ? '🔇' : '')
                + (!this.playing ? '⏸' : '▶')
                + (this.currentStation ? (this.currentStation.name + ':') : 'Select a station') + ' '
                + (this.currentSong ? this.currentSong.text : process.env.VUE_APP_TITLE)
        }
    },
}
</script>